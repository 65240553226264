import { Children, cloneElement, forwardRef, isValidElement, useContext } from 'react'

import { useButton } from 'reakit/Button'
import { omit } from 'remeda'

import type { PrefixAndSuffixAware } from './conditional-add-prefix-suffix'
import { ConditionalAddPrefixSuffix } from './conditional-add-prefix-suffix'
import {
    buttonWithLabelSizeToIconSizeMap,
    iconPaddingMap,
    sizeIconPaddingMap,
    sizeToButtonGroupIconButtonPaddingMap,
    sizeToFontSizeMap,
    sizeToIconSizeMap,
    sizeToMinWidthMap,
} from './size-mapping'
import type { LimitedSize, SchemaMapping, Variant } from '../../types'
import { isComponentInstance, isIcon } from '../../utils/component-type-guards'
import { SlotProvider } from '../../utils/components/slots'
import { getFocusRing, useFocusStyle } from '../../utils/focus'
import * as sizeMapping from '../../utils/size-mapping'
import { useReakitHook } from '../../utils/use-reakit-hook'
import { BorderBox } from '../border-box/border-box'
import type { Props as BoxProps } from '../box/box'
import { ButtonGroupContext } from '../button-group/button-group'
import { CircularProgress } from '../circular-progress/circular-progress'
import { GroupContext } from '../group/group-context'
import { ConditionalWrapWithTooltip } from '../tooltip-internal/conditional-wrap-with-tooltip'

export type Props = Omit<BoxProps, 'prefix'> &
    PrefixAndSuffixAware & {
        size?: LimitedSize
        variant?: Variant | SchemaMapping | 'brand'
        block?: boolean
        disabled?: boolean
        focusable?: boolean
        tooltip?: string
        isTooltipVisible?: boolean
        isLoading?: boolean
        isButtonWithLabel?: boolean
    }

export const ButtonBase = forwardRef<HTMLButtonElement, Props>(
    // eslint-disable-next-line complexity
    (
        {
            children,
            size,
            variant,
            prefix,
            suffix,
            style,
            block,
            tooltip,
            isTooltipVisible,
            isLoading,
            isButtonWithLabel,
            css,
            ...props
        },
        ref,
    ) => {
        const { size: groupSize, isInGroup, ...groupProps } = useContext(GroupContext)
        const { isInButtonGroup } = useContext(ButtonGroupContext)
        const isInGroupAndNotInButtonGroup = isInGroup && !isInButtonGroup

        const actualSize = size ?? groupSize ?? 'medium'

        // eslint-disable-next-line @eslint-react/no-children-to-array
        const isOnlyChild = typeof children === 'function' || Children.toArray(children).length === 1

        const defaultPadding = sizeMapping.padding[actualSize]
        const iconPadding = iconPaddingMap[actualSize]
        const mutablePaddings = {
            paddingLeft: prefix || isLoading ? iconPadding : defaultPadding,
            paddingRight: suffix ? iconPadding : defaultPadding,
        }

        const childElements =
            typeof children === 'function'
                ? children
                : // eslint-disable-next-line @eslint-react/no-children-map
                  Children.map(children, (child, index) => {
                      if (!isValidElement(child)) {
                          return child
                      }

                      const isCircularProgressElement = isComponentInstance(child, CircularProgress)
                      const isIconElement = isIcon(child)
                      const isIconLikeElement = isIconElement || isCircularProgressElement

                      if (isOnlyChild) {
                          if (!isIconLikeElement) {
                              return child
                          }

                          mutablePaddings.paddingLeft = iconPadding
                          mutablePaddings.paddingRight = iconPadding

                          if (isInButtonGroup) {
                              mutablePaddings.paddingLeft = sizeToButtonGroupIconButtonPaddingMap[actualSize]
                              mutablePaddings.paddingRight = sizeToButtonGroupIconButtonPaddingMap[actualSize]
                          }

                          // eslint-disable-next-line @eslint-react/no-clone-element
                          return cloneElement(child, {
                              paddingLeft: 'none',
                              paddingRight: 'none',
                          })
                      }

                      if (isIconLikeElement) {
                          mutablePaddings[index === 0 ? 'paddingLeft' : 'paddingRight'] = iconPadding

                          // eslint-disable-next-line @eslint-react/no-clone-element
                          return cloneElement(child, {
                              [index === 0 ? 'paddingRight' : 'paddingLeft']: sizeIconPaddingMap[actualSize],
                          })
                      }

                      return child
                  })

        const fontSize = sizeToFontSizeMap[actualSize]

        const resolvedIconSize = {
            size: isButtonWithLabel ? buttonWithLabelSizeToIconSizeMap[actualSize] : sizeToIconSizeMap[actualSize],
        }

        const { style: hookResultStyle = {}, ...hookResult } = useReakitHook(useButton, {
            ref,
            ...groupProps,
            style,
            ...props,
            /**
             * If the `isLoading` prop has a value "true", the button needs to be disabled, because, in the loading
             * state, the user shouldn't be able to interact with the element. If it's not provided, we can simply
             * rely on the `disabled` value, but when it's not provided, the `disabled` value from the "groupContext"
             * might be passed, because the consumer can still make the button disable via the `GroupContext`.
             */
            disabled: isLoading ? true : (props.disabled ?? groupProps.disabled),
            /**
             * If the `tooltip` prop is a string or the `isLoading` prop has a value "true", the button needs to be
             * explicitly set to be focusable. Otherwise, we use the consumer-provided value.
             */
            focusable: tooltip || isLoading ? true : props.focusable,
        })
        const minWidth = isButtonWithLabel ? sizeToMinWidthMap[actualSize] : undefined

        return (
            <SlotProvider
                slots={{
                    circularProgress: {
                        backgroundColor: 'transparent',
                        color: props.disabled ? 'border' : undefined,
                        ...resolvedIconSize,
                    },
                    icon: {
                        ...resolvedIconSize,
                    },
                }}
            >
                <ConditionalWrapWithTooltip tooltip={tooltip} visible={isTooltipVisible}>
                    <BorderBox
                        as="button"
                        type="button"
                        display="inline-flex"
                        borderRadius="medium"
                        width={block ? '100%' : 'auto'}
                        borderColor="transparent"
                        backgroundColor="transparent"
                        height={sizeMapping.height[actualSize]}
                        flexShrink={isInButtonGroup ? 'initial' : 0}
                        minWidth={minWidth}
                        css={[
                            {
                                fontSize,
                                lineHeight: fontSize,
                                alignItems: 'center',
                                justifyContent: 'center',
                                whiteSpace: 'nowrap',
                                cursor: 'pointer',
                                textDecoration: 'none',
                                gap: suffix || prefix || isLoading ? sizeIconPaddingMap[actualSize] : 0,
                                '&:hover': {
                                    textDecoration: 'none',
                                },
                                '&:focus': {
                                    textDecoration: 'none',
                                },
                                ...getFocusRing(useFocusStyle(props.disabled ? 'neutral' : variant)),
                                '&[aria-disabled="true"]': isLoading
                                    ? {
                                          cursor: 'wait',
                                      }
                                    : {
                                          color: 'border',
                                          cursor: 'not-allowed',
                                      },
                                '&:not([aria-disabled="true"])': {
                                    '&:active': {
                                        paddingTop: '1px !important',
                                    },
                                },
                            },
                            css,
                        ]}
                        {...mutablePaddings}
                        {...hookResult}
                        style={{
                            zIndex: isInGroupAndNotInButtonGroup ? 1 : undefined,
                            ...omit(hookResultStyle as Record<string, unknown>, ['pointerEvents']),
                        }}
                    >
                        {typeof childElements === 'function' ? (
                            childElements
                        ) : (
                            <ConditionalAddPrefixSuffix
                                prefix={isLoading ? <CircularProgress /> : prefix}
                                suffix={suffix}
                                size={size}
                            >
                                {childElements}
                            </ConditionalAddPrefixSuffix>
                        )}
                    </BorderBox>
                </ConditionalWrapWithTooltip>
            </SlotProvider>
        )
    },
)

ButtonBase.displayName = 'ButtonBase'
